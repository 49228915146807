import { useModal } from "../../../../utils/ModalContext";
import Button from "../../../../common/button";

import BannerStyleWrapper from "./Banner.style";
import useSnacks from "../../../../hooks/useSnacks";

const Banner = () => {
  const { mintModalHandle } = useModal();
  const { snacks } = useSnacks({});

  const settings = {
    count: 5432560,
    showTitle: true,
    size: 60,
    labelSize: 14,
    backgroundColor: "transparent",
    color: "#fff",
    dayTitle: "",
    hourTitle: "",
    minuteTitle: "",
    secondTitle: "",
    id: "countdownwrap",
  };
  const sellEnded = snacks && snacks.totalSupply === snacks.maxSupply;
  return (
    <BannerStyleWrapper className="bithu_v2_baner_sect" id="home">
      <div className="container">
        <div className="bithu_v2_baner_content">
          <h2>
            {" "}
            <span className="banner-emot"></span>Secret Snack Sandwich{" "}
          </h2>
          <h3>
            <span className="count">{snacks ? snacks.totalSupply : "??"}</span>{" "}
            / {snacks ? snacks.maxSupply : "??"} Minted
          </h3>
          {/* <div className="bithu_v2_timer">
            <h4>TIME LEFT</h4>
            <div className="timer timer_1">
              <CountdownTimer {...settings} />
            </div>
          </div> */}
          {sellEnded && <h3>Minted Out!</h3>}
          <div className="bithu_v2_baner_buttons text-center">
            <Button
              lg
              variant="mint"
              onClick={() => !sellEnded && mintModalHandle()}
            >
              {!sellEnded ? "Mint Now" : "View on Opensea"}
            </Button>
          </div>

          {/* <Particle className="v2_baner_stars" particles={data} /> */}
        </div>
      </div>
    </BannerStyleWrapper>
  );
};

export default Banner;
