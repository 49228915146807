import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import Footer from "../components/section/footer/v2";
import Header from "../components/section/header/v1/Header";
import { useModal } from "../utils/ModalContext";
import Collection from "../components/section/collection/Collection";


const Gallery = () => {
  const { visibility, walletModalvisibility } = useModal();

  return (
    <>
      <Layout>
        <GlobalStyles />
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        <Header />
        <Collection />

        <Footer />
      </Layout>
    </>
  );
};

export default Gallery;
