import { useMetaMask } from "metamask-react";
import { createContext, useContext, useState, useEffect } from "react";
import { ModalContext } from "./ModalContext";
import { Chains } from "../data/chains";
import Web3 from "web3";

export const AppContext = createContext({});

export const ContextProvider = ({ children }) => {
  const wallet = useMetaMask();

  const [chain, _setChain] = useState(Chains[4]);
  const web3 = new Web3(wallet.account ? wallet.ethereum : chain.rpc);
  if (wallet.status === "connected") {
    if (wallet.chainId != chain.chainId)
      wallet.switchChain(`0x${chain.chainId.toString(16)}`);

    if (wallet.chainId === chain.chainId)
      web3.eth.Contract.setProvider(wallet.ethereum);
  }

  const [visibility, setVisibility] = useState(false);
  const [walletModalvisibility, setModalvisibility] = useState(false);
  const [shareModalVisibility, setShareModalvisibility] = useState(false);

  const setChain = async (chain) => {
    _setChain(chain);
    try {
      await web3.givenProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.numberToHex(chain.chainId) }],
      });
    } catch (error) {
      console.log(error);
      if (error.code === 4902) {
        try {
          await web3.givenProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: web3.utils.numberToHex(chain.chainId),
                chainName: chain.name,
                rpcUrls: [chain.rpc],
                nativeCurrency: chain.nativeCurrency,
                blockExplorerUrls: chain.blockExplorerUrls,
              },
            ],
          });
        } catch (error) {
          alert(error.message);
        }
      }
    }
  };

  const mintModalHandle = () => {
    setVisibility(!visibility);
  };
  const walletModalHandle = () => {
    if (!wallet.account) {
      setModalvisibility(!walletModalvisibility);
    } else {
      setModalvisibility(false);
    }
  };
  const shareModalHandle = (e) => {
    e.preventDefault();
    setShareModalvisibility(!shareModalVisibility);
  };

  if (wallet.account && walletModalvisibility) setModalvisibility(false);

  return (
    <AppContext.Provider
      value={{
        chain,
        setChain,
        web3,
      }}
    >
      <ModalContext.Provider
        value={{
          visibility,
          mintModalHandle,
          walletModalHandle,
          walletModalvisibility,
          shareModalVisibility,
          shareModalHandle,
        }}
      >
        {children}
      </ModalContext.Provider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export default useAppContext;
