const data = [
  // {
  //   title: "Gallery",
  //   url: "/gallery",
  // },
  // {
  //   title: "Secret Sauce",
  //   url: "/secret-sauce",
  // },
];

export default data;
