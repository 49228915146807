import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./utils/ContextProvider";
import App from "./app/App";
import { MetaMaskProvider } from "metamask-react";
import { Toaster } from "react-hot-toast";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import config from "./config";

const client = new ApolloClient({
  uri: config.graph,
  cache: new InMemoryCache(),
});

if (
  window.ethereum.providerMap &&
  window.ethereum.providerMap.get("MetaMask")
) {
  window.ethereum = window.ethereum.providerMap.get("MetaMask");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MetaMaskProvider>
    <ApolloProvider client={client}>
      <ContextProvider>
        <Toaster />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ContextProvider>
    </ApolloProvider>
  </MetaMaskProvider>
);
