import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import ComingSoon from "../components/section/comingSoon/coming-soon";
import Footer from "../components/section/footer/v2";
import Header from "../components/section/header/v1/Header";
import { useModal } from "../utils/ModalContext";
const SecretSauce = () => {
  const { visibility, walletModalvisibility } = useModal();
  return (
    <>
      <Layout>
        <GlobalStyles />
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        <Header />
        <ComingSoon />

        <Footer />
      </Layout>
    </>
  );
};

export default SecretSauce;
