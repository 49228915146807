import styled from "styled-components";
import ComingSoonStyleWrapper from "./ComingSoon.style";
import SecretSauceImage from "../../../assets/images/secret-sauce.png";
const OrdersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ComingSoon = () => {
  return (
    <div>
      <ComingSoonStyleWrapper
        className="bithu_v2_baner_sect"
        id="home"
        style={{
          height: "80vh",
        }}
      >
        <h2
          style={{
            color: "#6f3a44",
            marginBottom: "20px",
          }}
        >
          Coming Soon
        </h2>
        <img src={SecretSauceImage} />
        <h8
          style={{
            color: "#6f3a44",
            marginBottom: "20px",
          }}
        >
        Secret Sandwhich NFTs incorporate the ERC2981 standards and the trading fees are set to 5%. 
        </h8>
        <h8
          style={{
            color: "#6f3a44",
            marginBottom: "20px",
          }}
        >
        All secondary market royalties will be placed into the Secret Sauce Jar.
        </h8>
        <h8
          style={{
            color: "#6f3a44",
            marginBottom: "20px",
          }}
        >
        The secret sauce will make sandwiches more tasty in the future.
        </h8>
      </ComingSoonStyleWrapper>
    </div>
  );
};

export default ComingSoon;
