import data from "../../../../../assets/data/footer/footerLinks";
import data2 from "../../../../../assets/data/footer/footerHelpLinks";
import footerLogo from "../../../../../assets/images/logo.png";
import FooterBottomStyleWrapper from "./FooterBottom.style";

const FooterBottom = () => {
  return (
    <FooterBottomStyleWrapper className="bithu_v2_main_footer">
      <div className="v2_main_footer">
        <div className="v2_footer_menu">
          <div className="bottom_footer_menulist">
            <ul>
              {data?.map((item, i) => (
                <li key={i}>
                  <a href={item.url}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="v2_footer_project_text">
            <p>A Ceazor project</p>
          </div>
          <div className="v2_footer_copiright_text">
            <p>Minters of these NFTs do not expect to obtain any financial gain, nor do they expect to obtain ownership rights to any system or entity.</p>
          </div>
        </div>
        <div className="v2_footer_help_links">
            <h2>Want to know why this project exists?</h2>
            <a href={data2[0].url}>{data2[0].title}</a>
            <p>...</p>
            <h2>Want to know more about the NFTs?</h2>
            <a href={data2[1].url}>{data2[1].title}</a>
            <p>...</p>
        </div>
          <div className="v2_footer_logo">
            <a href="# ">
              <img src={footerLogo} alt="bithu nft logo" />
            </a>
          </div>
      </div>
    </FooterBottomStyleWrapper>
  );
};

export default FooterBottom;
