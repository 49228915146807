import useSnacks from "../../../hooks/useSnacks";
import CollectionStyleWrapper from "./Collection.style";
import SandwichSample from "../../../assets/images/sandwichSample.png";
import Order from "../../../common/order";
import { useEffect } from "react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import { useMetaMask } from "metamask-react";
import Button from "../../../common/button";
import { useModal } from "../../../utils/ModalContext";
const OrdersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Collection = () => {
  const wallet = useMetaMask();
  const { mintModalHandle } = useModal();

  const { loading, error, data } = useQuery(
    gql`
      query GetProfile($id: String!) {
        profile(id: $id) {
          address
          nfts {
            tokenId
            external_url
            image
            attributes {
              trait_type
              value
            }
          }
        }
      }
    `,
    {
      variables: {
        id: wallet?.account,
      },
    }
  );
  const { snacks } = useSnacks({});

  const sellEnded = snacks && snacks.totalSupply === snacks.maxSupply;

  return (
    <div>
      <CollectionStyleWrapper className="bithu_v2_baner_sect" id="home">
        <h2
          style={{
            color: "#6f3a44",
          }}
        >
          Your Orders
        </h2>
        <OrdersContainer>
          {loading ? (
            <div
              style={{
                width: "50%",
                height: "60vh",
              }}
            >
              <p
                style={{
                  marginTop: "20vh",
                }}
              >
                loading...
              </p>
            </div>
          ) : null}
          {data && data.profile && data.profile.nfts
            ? data.profile.nfts.map(({ image, attributes, tokenId }) => (
                <Order src={image} nftID={tokenId} attributes={attributes} />
              ))
            : null}
        </OrdersContainer>
        {/* {snacks && snacks.minted} */}
        {!sellEnded && (
          <Button lg variant="mint" onClick={() => mintModalHandle()}>
            {" "}
            Still Hungry?
          </Button>
        )}
      </CollectionStyleWrapper>
    </div>
  );
};

export default Collection;

// Example result for data
/*
{
  "data": {
    "profile": {
      "nfts": [
        {
          "tokenId": "0",
          "image": "https://gateway.pinata.cloud/ipfs/QmdoLrudVmozkA1kKdjEBUzvbzhU7f3NukyYFF1vdV5nhB/0",
          "attributes": [
            {
              "trait_type": "Background",
              "value": "kitchen"
            },
            {
              "trait_type": "Bread",
              "value": "wholewheat"
            },
            {
              "trait_type": "Cheese",
              "value": "cheddar"
            },
            {
              "trait_type": "Extras",
              "value": "pickles"
            },
            {
              "trait_type": "Meat",
              "value": "ham"
            },
            {
              "trait_type": "Plate",
              "value": "cuttingboard"
            },
            {
              "trait_type": "Sauce",
              "value": "mayonnaise"
            },
            {
              "trait_type": "Vegetables",
              "value": "romaine"
            }
          ]
        },
        {
          "tokenId": "2",
          "image": "https://gateway.pinata.cloud/ipfs/QmdoLrudVmozkA1kKdjEBUzvbzhU7f3NukyYFF1vdV5nhB/2",
          "attributes": [
            {
              "trait_type": "Background",
              "value": "kitchen"
            },
            {
              "trait_type": "Bread",
              "value": "multigrain"
            },
            {
              "trait_type": "Cheese",
              "value": "cheddar"
            },
            {
              "trait_type": "Extras",
              "value": "pickles"
            },
            {
              "trait_type": "Meat",
              "value": "roast beef"
            },
            {
              "trait_type": "Plate",
              "value": "cuttingboard"
            },
            {
              "trait_type": "Sauce",
              "value": "peanut butter"
            },
            {
              "trait_type": "Vegetables",
              "value": "kale"
            }
          ]
        }
      ]
    }
  }
}
*/
