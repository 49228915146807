const environment = "production"; // 'test'

const testEnv = {
  graph: "https://api.thegraph.com/subgraphs/name/jonomnom/sss-test-goerli",
  network: {
    symbol: "ETH",
    chainId: 5,
    name: "Goerli",
  },
  contract: "0x5a220fe4cd1d7fe7856afe474fb6e371a52dc44e",
  erc20: {
    USDC: {
      address: "0x7b69b0e08de2992e482cd820f765c065efadafbe",
      decimals: 6,
    },
  },
};

const productionEnv = {
  graph:
    "https://api.thegraph.com/subgraphs/name/jonomnom/secret-snack-sandwich",
  network: {
    symbol: "ETH",
    chainId: 1,
    name: "Ethereum",
  },
  contract: "0x626Cb677412E672Ef115cC5Df85828746FBAd29A",
  erc20: {
    USDC: {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals: 6,
    },
  },
};

const config = environment === "test" ? testEnv : productionEnv;

export default config;
