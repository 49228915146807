import React, { useState } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  // margin: auto;
  margin: 20px;
  @media (max-width: 768px) {
    margin: auto;
  }
`;
const Container = styled.div`
  background-color: #f3d7ae;
  padding: 20px;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;

  margin: 20px;
`;

const Title = styled.div`
  text-align: center;
  color: #6f3a44;
  font-weight: bold;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #6f3a44;
`;

const SandwichPic = styled.img`
  max-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Linewrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: baseline;
`;

const Middle = styled.div`
  border-bottom: 1px dotted #aaa;
  flex-grow: 1;
  margin: 0 5px;
  height: 5px;
`;
const AttributeContainer = styled.div`
  margin: auto;
  display: flex;
  color: #6f3a44;
`;
const Order = ({ className, src, nftID, attributes, ...rest }) => {
  return (
    <Wrapper>
      <Container>
        <Title>Order: NFT ID #{nftID}</Title>
        <SandwichPic className={className} src={src} alt="sandwich" />
        {attributes.map(({ trait_type, value }) => {
          return (
            <>
              <AttributeContainer>
                <Linewrapper>
                  <div>{trait_type}:</div>
                  <Middle />
                </Linewrapper>
                <div>{value}</div>
              </AttributeContainer>
            </>
          );
        })}
      </Container>
    </Wrapper>
  );
};

export default Order;
