import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/mint-img.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import useSnacks from "../../../hooks/useSnacks";
import useAppContext from "../../../utils/ContextProvider";
import { useMetaMask } from "metamask-react";
import { NFT_ABI } from "../../../data/nft-abi";
import ERC20_ABI from "../../../data/erc20-abi";
import { ethers, providers } from "ethers";
import toast from "react-hot-toast";
import config from "../../../config";

const MintNowModal = () => {
  const [count, setCount] = useState(1);
  const { mintModalHandle } = useModal();
  const wallet = useMetaMask();
  const { web3 } = useAppContext();
  const { snacks } = useSnacks({});
  const totalSupply = snacks ? snacks.totalSupply : "-";
  const maxSupply = snacks ? snacks.maxSupply : "-";
  const price = snacks ? snacks.price / 1e6 : "-";
  const minted = snacks ? snacks.minted : "-";
  const balance = snacks ? snacks.balance / 1e6 : "-";
  const maxMint = snacks ? snacks.maxMint : 1;
  const approved = snacks ? snacks.allowance / 1e6 : 0;
  const isApproved = approved >= price * count;
  console.log(`isApprove: ${isApproved}`);

  const approve = async () => {
    toast.loading("Awaiting signature");
    try {
      if (!wallet.account) {
        toast.dismiss();
        toast.error("Please connect your wallet");
        return;
      }
      wallet.connect(); // jono is not sure what this does
      if (wallet.chainId != config.network.chainId) {
        toast.dismiss();
        toast.error(`Please switch to ${config.network.name}`);
      }
      console.log(wallet);
      const { transactionHash } = await snacks.usdc.methods
        .approve(
          snacks.nft._address,
          ethers.utils.parseUnits((price * count).toString(), 6)
        )
        .send({ from: wallet.account });
      console.log(transactionHash);
      if (!transactionHash) {
        toast.dismiss();
        toast.error("No tx submitted.");
        return;
      }
      const f = await wallet.connect();
      console.log(f);
      // TODO - confirm approval
    } catch (e) {
      toast.dismiss();
      toast.error((e && e.message) || e);
      return;
    }
    toast.dismiss();
    toast.success("Tx submitted. Check your metamask is tx is successful.");
  };
  const mint = async () => {
    toast.loading("Minting");
    try {
      if (!wallet.account) return wallet.connect();
      await snacks.nft.methods.mint(count).send({ from: wallet.account });
      const f = await wallet.connect();
      toast.success("???");
      console.log(f);
      // TODO - confirm mint
    } catch (e) {
      toast.dismiss();
      toast.error((e && e.message) || e);
      return;
    }
    toast.dismiss();
    toast.success("Tx submitted. Check your metamask is tx is successful.");
  };

  return (
    <>
      <MintModalStyleWrapper
        className="modal_overlay"
        onClick={() => {
          mintModalHandle();
        }}
      >
        <div
          className="mint_modal_box"
          onClick={(e) => {
            // stops propagation so when clicking inside of mint_modal_box, wrapper onclick is not triggered
            if (e.stopPropagation) {
              e.stopPropagation(); // W3C model
            } else {
              e.cancelBubble = true; // IE model
            }
          }}
        >
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Snag Some Snacks</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="mint_img">
                <img src={mintImg} alt="bithu nft mint" />
              </div>
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {maxSupply - totalSupply}/<span>{maxSupply}</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5>
                    <h5>{price} USDC</h5>
                  </li>
                  <li>
                    <h5>Balance</h5>
                    <h5>{balance} USDC</h5>
                  </li>
                  <li>
                    <h5>Minted ({`Max ${maxMint}`})</h5>
                    <h5>{minted} NFTs</h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button
                        onClick={() =>
                          count > 1 ? setCount(count - 1) : count
                        }
                      >
                        -
                      </button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) =>
                          setCount(Math.min(e.target.value, maxMint - minted))
                        }
                      />
                      <button
                        onClick={() => {
                          if (count + minted <= maxMint) setCount(count + 1);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <h5>{price * count} USDC</h5>
                  </li>
                </ul>
              </div>
              {count + minted >= maxMint ? (
                <p
                  style={{
                    margin: 0,
                    marginTop: -20,
                    marginBottom: 16,
                  }}
                >
                  Limit to {maxMint} per person
                </p>
              ) : null}
              <div className="modal_mint_btn">
                <Button lg variant="mint" onClick={isApproved ? mint : approve}>
                  {isApproved ? "Mint Now (2/2)" : "Approve Spend (1/2)"}
                </Button>
              </div>
              {minted > 0 && (
                <>
                  <div
                    style={{
                      padding: 6,
                    }}
                  ></div>
                  <div className="modal_mint_btn">
                    <a
                      href="/gallery"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button md variant="mint" onClick={() => {}}>
                        {`View your Gallery (${minted})`}
                      </Button>
                    </a>
                  </div>
                </>
              )}
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
