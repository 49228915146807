export const Chains = [
  {
    name: "Fantom Testnet",
    chainId: 4002,
    image: "/img/chains/ftm.svg",
    rpc: "https://rpc.testnet.fantom.network/",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  {
    name: "Fantom Opera",
    chainId: 250,
    image: "/img/chains/ftm.svg",
    rpc: "https://rpc.ftm.tools",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  {
    name: "Polygon Testnet",
    chainId: 80001,
    image: "/img/chains/ftm.svg",
    rpc: "https://polygon-mumbai.g.alchemy.com/v2/Oc0JlIPyG3Zv7LwJGmwI2QN7r9m6IlHL",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com//"],
  },
  {
    name: "Goerli Network",
    chainId: 5,
    image: "/img/chains/ftm.svg",
    rpc: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://goerli.etherscan.com//"],
  },
  {
    name: "Ethereum Mainnet",
    chainId: 1,
    image: "/img/chains/ftm.svg",
    rpc: "https://eth.llamarpc.com",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.com//"],
  },
];
