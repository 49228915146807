const data2 = [
  {
    title: "Living on MoM's Dollar",
    url: "https://medium.com/@ceazor/living-on-moms-dollar-dffc1bcd9665",
  },
  {
    title: "Ceazor’s Secret Snack Sandwich",
    url: "https://medium.com/@ceazor/ceazors-secret-snack-sandwich-13ea11743030",
  },
];

export default data2;
