import { Routes, Route } from "react-router-dom";
import Gallery from "../pages/gallery";
import Home from "../pages/home";
import SecretSauce from "../pages/secret-sauce";
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact strict path="/gallery" element={<Gallery />} />
      <Route exact strict path="/secret-sauce" element={<SecretSauce />} />
    </Routes>
  );
}

export default App;
