import BN from "bignumber.js";
import { useMetaMask } from "metamask-react";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import web3 from "web3";
import config from "../config";
import ERC20_ABI from "../data/erc20-abi";
import { NFT_ABI } from "../data/nft-abi";
import useAppContext from "../utils/ContextProvider";

export default function useSnacks() {
  const { web3 } = useAppContext();
  const wallet = useMetaMask();

  const [snacks, setSnacks] = useState(null);

  useDeepCompareEffect(() => {
    const onLoad = async () => {
      if (!web3) return;

      if (wallet.status !== "connected") return;
      const accounts = await web3.eth.getAccounts();
      console.log("ACCOUNTS");
      console.log(accounts);

      const nftAddress = config.contract;
      const usdcAddress = config.erc20.USDC.address;
      // test
      const nft = new web3.eth.Contract(NFT_ABI, nftAddress);
      const usdc = new web3.eth.Contract(ERC20_ABI, usdcAddress);
      const totalSupply = await nft.methods.totalSupply().call();
      const maxSupply = await nft.methods.maxSupply().call();
      const price = await nft.methods.price().call();
      const maxMint = await nft.methods.maxMint().call();
      const allowance = wallet.account
        ? await usdc.methods.allowance(wallet.account, nft._address).call()
        : 0;
      const balance = wallet.account
        ? await usdc.methods.balanceOf(wallet.account).call()
        : 0;
      const minted = wallet.account
        ? Math.max(
            await nft.methods.balanceOf(wallet.account).call(),
            snacks && snacks.minted
          )
        : 0;

      // console.log(`totalSupply: ${totalSupply}`)
      // console.log(`maxSupply: ${maxSupply}`)
      // console.log(`allowance: ${allowance}`)
      setSnacks({
        web3,
        nft,
        usdc,
        totalSupply,
        maxSupply,
        price,
        maxMint,
        allowance,
        balance,
        minted,
      });
    };
    onLoad();
  }, [wallet, web3]);

  return {
    snacks,
  };
}
